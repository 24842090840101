import './app.scss';
import { InitialMenuScene } from './Scenes/InitialMenuScene/InitialMenuScene';
import { useContext } from "react";
import { GameContext } from "./contexts/GameContext";
import { GameOver } from "./Scenes/GameOverScene/GameOver";
import memeleader from './solanaassets/czleader_small.png'
import useSound from 'use-sound';
import { SocialIcon } from 'react-social-icons'
import {
  GrDeploy  
} from 'react-icons/gr'

import CustomButton2 from './ui/CustomButton2'
import { TypeAnimation } from 'react-type-animation';
import {
  GrGamepad
} from 'react-icons/gr'
import { PiSpeakerNoneFill } from "react-icons/pi";
import { useRef } from 'react';

import CustomButton from './ui/CustomButton'
import Main from './ui/Main'

import ReactGA from "react-ga4";

const TRACKING_ID = "G-ZXCQETDMHV";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

function App() {
  const gameRef = useRef(null)
  const { gameState } = useContext(GameContext);
  const [playSound] = useSound('czsong.mp3');


  window.addEventListener('keydown', function (e) {
    if (e.keyCode === 32 && e.target === document.body) {
      e.preventDefault();
    }
  });



  const executeScroll = () => gameRef.current.scrollIntoView()

  return (
    <div className="App" >
      <div className="font-body w-full max-w-screen-xl mx-auto flex flex-col h-screen justify-between scroll-smooth snap-y snap-mandatory" >
        <Main />
        <div className="w-full px-2 mt-16">
          <p className="font-bold cursor-pointer text-center text-5xl text-secondary epilepsy" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
            What is $czSAFU?
          </p>
          <p className="mt-8 text-3xl text-center text-[#ffffff]" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
            We all unite to support the man.<br/> Help on everything he  needs while increasing size <br/>and building everyday for a better economy.<br/><br/>
            <span style={{color:'#ffe700'}}>No Boundaries on the Memespace</span>
            

          </p>
          <div className='w-full text-center mx-auto'>
          <p className="mt-8 text-3xl text-center text-[#ffffff]" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
            SOLANA CA
          </p>
          <p className="mt-1 text-lg text-center text-[#ffffff]" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
            6evCKsFyykK97ufBdTWVkqhW8wQXm2Lis9NYTFKsYYBD
          </p>
          <a href="https://jup.ag/swap/SOL-6evCKsFyykK97ufBdTWVkqhW8wQXm2Lis9NYTFKsYYBD"  target="_blank" rel="noopener noreferrer" >
              <CustomButton2 className=" md:flex mt-8 px-4 py-2 hover:bg-secondary hover:bg-secondary hover:scale-105 hover:transition hover:ease-out duration-300 mx-auto">
                <GrDeploy   className="mr-2 -ml-1 w-4 h-4" />
                BUY NOW ON JUPITER
              </CustomButton2>
            </a>
          </div>
          
        <div className="w-full px-2 mt-16" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
          <p className="font-bold cursor-pointer text-center text-5xl text-secondary epilepsy">
            chat wif us
          </p>
          <div className='pt-6 w-full items-center text-center space-x-6'>
            <SocialIcon url="https://x.com/CZwifSAFU" />
            <SocialIcon url="https://t.me/czwifsafuentry" />
          </div>
        </div>
          <CustomButton className="hidden md:flex mt-8 px-4 py-2 hover:bg-secondary hover:bg-secondary hover:scale-105 hover:transition hover:ease-out duration-300 mx-auto" onClick={executeScroll}>
            <GrGamepad className="mr-2 -ml-1 w-4 h-4" />
            Click for special
          </CustomButton>
          <img className='pt-5 mx-auto ' src={memeleader} alt='memeking' />
        </div>
        <div className="w-full px-2 mt-16">
          <p className="font-bold cursor-pointer text-center text-5xl text-secondary" style={{ color: '#ffe700', textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
           The only one that took it for the industry to succeed
          </p>
          <p className="mt-8 text-6xl text-center text-[#ffffff] epilepsy" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
            HE KEPT OUR FUNDS SAFU
          </p>
        </div>



        <div ref={gameRef} className="flex-grow sm:text-l md:text-3xl text-secondary p-14 text-center font-bold pt-30 pb-40 hidden md:block" id="rockegg">
        <span style={{ color: '#74ee15' }}>Help CZ WIN DOWN BELLOW</span><br />
              
          <section className=" px-4 sm:-mb-16 sm:mt-4 center mx-auto" style={{ margin: '0 auto' }}>
            {gameState.gameOver ?
              <GameOver />
              :
              <InitialMenuScene />

            }
          </section>
        </div>
        <p className="pt-16 font-bold cursor-pointer text-center  text-2xl sm:text-3xl text-secondary pb-10 epilepsy md:hidden" style={{ textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }}>
          EASTER EGG IF U ACESS THRU PC
        </p>
      </div>
    </div>

  );
}

export default App;
