import {
  GrDeploy  
} from 'react-icons/gr'

// custom imports
import CustomButton from './CustomButton'
import CustomButton2 from './CustomButton2'
import logocoin from './../solanaassets/czwifsafu_small.png'
import { PiSpeakerNoneFill } from "react-icons/pi";
import useSound from 'use-sound';
import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-router-dom';

const Main = () => {


  const [playSound] = useSound('czsong.mp3');
  return (
    <main className="w-full flex-grow sm:text-l md:text-xl  text-secondary text-center font-bold" >
      <section className="px-4 sm:-mb-16 sm:mt-4 center">
        <div className="pt2 sm:pt-24 pb-8 px-6 flex flex-col sm:flex-row sm:space-x-16 space-y-16 sm:space-y-0">
          <div className="mt-1 sm:mt-10  bg-contain bg-no-repeat sm:w-full sm:h-auto hover:scale-105 hover:rotate-[-.3deg] hover:transition hover:ease-out duration-300" style={{ background: { logocoin } }}>
            <img className="facemove" src={logocoin} alt="CzWifSafu $czSAFU" />

          </div>
          <div className="w-full mx-auto" >
            <p className="font-bold cursor-pointer text-center text-6xl text-secondary " style={{ color: '#ffe700', textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black' }} >
              $CZwifSAFU
            </p>
            <p className="mt-8 text-3xl text-center justify-center text-[#ffffff]" style={{
              textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black',
            }}>
              The king has been arrested.<br />
              He gave crypto industry a lot.<br />
              Let's make sure we support him<br />
              <br />

              A lot utility incoming where funds revert to support CZ.<br /><br />
              <span style={{ color: '#74ee15' }}>In case CZ doesn't need it, we buy back.</span><br />


              <span style={{ color: 'rgb(255 0 214)' }}>oh... and we puttin CZ also on the sphere</span><br /><br />


              Let the community grow and always follow CZ principles<br />
            </p>
            <div className='w-full text-center mx-auto pt-4' style={{ textAlign: 'center', margin: '0 auto' }}>
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  'Build ',
                  500, // wait 1s before replacing "Mice" with "Hamsters"
                  'Positive',
                  500,
                  'Focused',
                  500,
                  'F*CK FUD',
                  6000
                ]}
                wrapper="span"
                speed={50}
                className='w-full pt-4 text-center mx-auto'
                style={{
                  fontSize: '3em', display: 'inline-block', textAlign: 'center', color: '#ffe700', margin: '0 auto',
                  textShadow: '2px 0 black, 0 2px black, 4px 0 black, 0 -4px black'
                }}
                repeat={Infinity}
              />
            </div>
            <a href="https://jup.ag/swap/SOL-6evCKsFyykK97ufBdTWVkqhW8wQXm2Lis9NYTFKsYYBD"  target="_blank" rel="noopener noreferrer" >
              <CustomButton2 className=" md:flex mt-8 px-4 py-2 hover:bg-secondary hover:bg-secondary hover:scale-105 hover:transition hover:ease-out duration-300 mx-auto">
                <GrDeploy   className="mr-2 -ml-1 w-4 h-4" />
                BUY NOW ON JUPITER
              </CustomButton2>
            </a>
            <div className="hidden md:flex mt-8 flex justify-center space-x-4 mx-auto font-bold whitespace-nowrap">

              <CustomButton className="px-4 py-2 hover:bg-secondary hover:bg-secondary hover:scale-105 hover:transition hover:ease-out duration-300" id='playButton' onClick={playSound}>
                <PiSpeakerNoneFill className="mr-2 -ml-1 w-4 h-4" />
                WIF SAFU?
              </CustomButton>

            </div>
            <div className="mt-8 flex justify-center space-x-4 mx-auto font-bold whitespace-nowrap">
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Main
