export const spaceshipsTypes = [
  {
    id: 0,
    name: "KILL WIF FIRE",
    description: "Better than CZ, is CZ with fire",
    damage: 1,
    vel: 7,
    height: 90,
    width: 90,
    shotType: "default",
    imageSrc: `${process.env.PUBLIC_URL}/images/spaceshipsSkins/nave2.gif`,
    cooldown: 1000,
    initialMunition: 8,
  },
  {
    id: 1,
    name: "F POLICE",
    description: "F*CK WIF THE POLICE",
    damage: 1,
    vel: 8,
    height: 110,
    width: 100,
    shotType: "doubleShot",
    imageSrc: `${process.env.PUBLIC_URL}/images/spaceshipsSkins/nave1.gif`,
    cooldown: 650,
    initialMunition: 6,
  },
  {
    id: 2,
    name: "CZ BLOCKKING?",
    description: "INNOVATION BRAH",
    damage: 3,
    vel: 7,
    height: 200,
    width: 150,
    shotType: "default",
    imageSrc: `${process.env.PUBLIC_URL}/images/spaceshipsSkins/nave3.gif`,
    cooldown: 1400,
    initialMunition: 4,
  }
]
